<template>
    <backoffice-layout :title="$tc('booking', 2)">
        <v-card>
            <v-table :rows="bookings" :columns="columns" :search-options="{ enabled: true, placeholder: $t('action.search_bookings') }" @on-cell-click="props => $redirect('admin-booking-detail', {id: props.row.id})">
                <template #table-row="props">
                    <template v-if="props.column.field == 'customer'">
                        {{ props.row.user.fullname }}

                        <a v-if="props.row.user.mobile" :href="`tel:${props.row.user.mobile}`" class="block text-blue-500 hover:underline" @click="$event.stopPropagation()"> {{ props.row.user.mobile }} </a>
                    </template>

                    <template v-else-if="props.column.field == 'itinerary'">
                        <div class="flex items-center">
                            <span class="block bg-blue-gray-100 rounded-full w-8 h-8 basis-8 flex-grow-0 flex-shrink-0 mr-4 flex items-center justify-center">
                                <v-icon name="arrow-down" size="sm" />
                            </span>

                            <div class="flex flex-col">
                                <p class="mb-1"> {{ props.row.pickup_address }} </p>

                                <p> {{ props.row.dropoff_address }} </p>
                            </div>
                        </div>
                    </template>
                </template>
            </v-table>
        </v-card>
    </backoffice-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
		return {
            columns: [{
                label: 'booking_id',
                field: 'token',
                width: '140px'
            }, {
                label: 'customer',
                field: 'customer',
                width: '180px'
            }, {
                label: 'itinerary',
                field: 'itinerary',
                width: '280px'
            }, {
                label: 'date_time',
                field: 'schedule_time',
                type: 'date',
                width: '260px'
            }, {
                label: 'price',
                field: 'price',
                type: 'price',
                width: '80px'
            }, {
                label: 'status',
                field: 'status',
                type: 'tag',
                width: '100px'
            }]
        }
    },

    computed: {
        ...mapGetters('admin_bookings', [
            'bookings',
        ]),
    },

    mounted () {
        this.getBookings();
    },

    methods: {
		...mapActions('admin_bookings', [
            'getBookings',
		]),
    }
}
</script>
